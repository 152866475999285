import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import { utils } from '@jobdone/jobdone-web-design-system';
import { AnimatePresence } from 'framer-motion';
import Amplify, { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import OpenReplayContainer from '@components/OpenReplayContainer';
import { OpenReplayTrackerProvider } from '../../lib/openReplayContext';
import { useApollo } from '../../lib/apolloClient';
import awsConfig from '../../aws-config';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

const { ThemeProvider } = utils;

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <OpenReplayTrackerProvider>
      <OpenReplayContainer />
      <ThemeProvider>
        <ApolloProvider client={apolloClient}>
          <RecoilRoot>
            <AnimatePresence exitBeforeEnter>
              <Component {...pageProps} />
            </AnimatePresence>
          </RecoilRoot>
        </ApolloProvider>
        <ToastContainer
          position='bottom-left'
          pauseOnHover
        />
      </ThemeProvider>
    </OpenReplayTrackerProvider>
  );
}

export default appWithTranslation(MyApp);
