// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const getSentryEnv = () => {
  const env = process.env.NEXT_PUBLIC_SENTRY_ENV;

  switch (env) {
    case 'production': {
      return 'production'
    }
    case 'staging': {
      return 'staging'
    }
    case 'development': {
      return 'development'
    }
    default: {
      return 'local'
    }
  }
}

const sentryEnv = getSentryEnv()

if (sentryEnv !== 'local') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://48c40c6e8bf640deb6dde8b45cd18d57@sentry.jobdone-development.app/17',
    environment: sentryEnv,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
