import useAuth from '@common/hooks/useAuth';
import { useContext, useEffect } from 'react';
import { OpenReplayTrackerContext } from '../../../lib/openReplayContext';

function OpenReplayContainer() {
  const { initTracker, startTracking, stopTracker } = useContext(OpenReplayTrackerContext);
  const { data: auth } = useAuth();

  useEffect(() => {
    const ingestPoint = process.env.NEXT_PUBLIC_OPENREPLAY_INGEST_POINT;
    const projectKey = process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY;
    const identityUserEmail = auth?.attributes.email;

    if (identityUserEmail && ingestPoint && projectKey) {
      initTracker?.({
        userIdEnabled: true,
        getUserId: () => identityUserEmail,
        ingestPoint,
        projectKey,
      });
      startTracking?.();
    } else {
      stopTracker?.();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.attributes.email]);

  return <></>;
}

export default OpenReplayContainer;
