import {
  createContext, ReactNode, useEffect, useReducer,
} from 'react';
import Tracker from '@openreplay/tracker/cjs';
import { v4 as uuidV4 } from 'uuid';

interface OpenReplayConfigInterface {
  userIdEnabled?: boolean;
  getUserId?: () => string
  projectKey?: string
  ingestPoint?: string
}

interface OpenReplayTrackerContextInterface {
  startTracking?: () => void,
  initTracker?: (config: OpenReplayConfigInterface) => void,
  stopTracker?: () => void
}

interface OpenReplayTrackerProviderInterface {
  children: ReactNode
}

export const OpenReplayTrackerContext = createContext<OpenReplayTrackerContextInterface>({});

function defaultGetUserId() {
  return uuidV4();
}

function newTracker(config: OpenReplayConfigInterface) {
  const getUserId = (config?.userIdEnabled && config?.getUserId) ? config.getUserId : defaultGetUserId;
  let userId = null;

  console.log('DEBUG: process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY: ', process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY);
  console.log('DEBUG: process.env.NEXT_PUBLIC_OPENREPLAY_INGEST_POINT: ', process.env.NEXT_PUBLIC_OPENREPLAY_INGEST_POINT);

  const trackerConfig = {
    projectKey: config?.projectKey || '',
    ingestPoint: config?.ingestPoint || '',
  };

  console.log(`OR config: ${JSON.stringify(trackerConfig)}`);

  const tracker = new Tracker(trackerConfig);

  if (config?.userIdEnabled) {
    userId = getUserId();
    console.log('DEBUG: userId: ', userId);
    tracker.setUserID(userId);
  }
  return tracker;
}

function reducer(
  state: {
    tracker: Tracker | null,
    config: OpenReplayConfigInterface
  },
  action: {
    type: 'init',
    config: OpenReplayConfigInterface
  } | {
    type: 'start',
  } |
  {
    type: 'stop',
  },
) {
  switch (action.type) {
    case 'init': {
      if (!state.tracker) {
        console.log('Instantiaing the OR for the first time...');
        return { ...state, config: action.config, tracker: newTracker(action.config) };
      }
      return state;
    }
    case 'start': {
      console.log('Starting OR...');
      console.log(`Custom configuration received: ${JSON.stringify(state.config)}`);
      void state?.tracker?.start();
      return state;
    }
    case 'stop': {
      console.log('Stopping OR...');
      console.log(`Custom configuration received: ${JSON.stringify(state.config)}`);
      void state?.tracker?.stop();
      return { ...state, config: {}, tracker: null };
    }
    default:
      return state;
  }
}

export function OpenReplayTrackerProvider({ children }: OpenReplayTrackerProviderInterface) {
  const [state, dispatch] = useReducer(reducer, { tracker: null, config: {} });

  useEffect(() => {
    if (state?.tracker) {
      void import('@openreplay/tracker-assist/cjs').then(({ default: trackerAssist }) => {
        state.tracker?.use(trackerAssist());
      });
    }
  }, [state.tracker]);

  const value = {
    startTracking: () => dispatch({ type: 'start' }),
    initTracker: (config: OpenReplayConfigInterface) => dispatch({ type: 'init', config }),
    stopTracker: () => dispatch({ type: 'stop' }),
  };

  return (
    <OpenReplayTrackerContext.Provider value={value}>
      {children}
    </OpenReplayTrackerContext.Provider>
  );
}
